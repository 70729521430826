import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import i18n from './i18n';
import useLocaleStore from "./store/useLocaleStore";
import { useTranslation } from "react-i18next";
import Slider from "./components/Slider/Slider";
import Help from "./assets/Help";
import Tutorial from "./assets/Tutorial";
function Header() {

  const { setLocale } = useLocaleStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation()
  const isFrenchPathDownload = location.pathname.startsWith("/Catalog/fr") || location.pathname.startsWith("/Catalog") || location.pathname.startsWith("/Catalog/en");
  useEffect(() => {
    const lowerCasePath = location.pathname.toLowerCase();

    switch (lowerCasePath) {
      case "/en" || "/EN":
        setLocale('en');
        i18n.changeLanguage('en');
        break;
      case "/us" || "/US":
        setLocale('us');
        i18n.changeLanguage('us');
        break;
      case "/fr" || "/FR":
        setLocale('fr');
        i18n.changeLanguage('fr');
        break;
      default:
        // Handle the default case here
        break;
    }
  }, [location]);


  return (
    <>

      <header className="bg-black mb-3">
        <div className="header-container">
          <div className="d-flex align-items-center gap-5">
            <div className="bg-white logo-wp">
              <a href="/" className="logo">
                <img width={250} alt="catalog logo" src="/images/logo-alt.png" className="App-logo2" />
              </a>
            </div>
            <div className="w-100 d-flex align-items-center">
              <Slider />
            </div>
            <div className="d-flex align-items-center">
              <Help />
              <Tutorial />
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
