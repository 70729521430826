import React from 'react'
import Header from '../header';
import Footer from "../footer";
import ToasterProvider from '../components/ToasterProvider';
// Layout component
const layout = ({ children }) => {
    return (
        <div className="App">
            <ToasterProvider />
            <div className="bg">
                <div className="gradient"></div>
            </div>
            <Header />
            <main className="App-content">
                {/* <section className='maintenance-container d-flex justify-content-center align-items-center flex-column' style={{ height: "800px" }}>
                    <div className='w-full d-flex justify-content-center align-items-center'>
                        <img src="/images/maintenance.png" alt="Maintenance" width="550" height="auto" />
                    </div>
                    <h2>Website under maintenance....</h2>
                    <p>Our website is currently undergoing scheduled maintenance.</p>
                    <p>We should be back shortly. Thank you for your patience.</p>
                </section> */}
                {children}

            </main>
            <Footer />
        </div>
    );
};

export default layout



