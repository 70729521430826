import React from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import i18n from 'i18next';
import useLocaleStore from '../../store/useLocaleStore';

const LocaleChange = ({ onLocaleChange }) => {
    const { locale, setLocale } = useLocaleStore();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const newLocale = e.target.value;
        i18n.changeLanguage(newLocale);
        setLocale(newLocale);
        navigate(`/${newLocale}`);
        onLocaleChange();
    };

    return (
        <Form.Select
            aria-label="Select Locale"
            value={locale}
            onChange={handleChange}
            size="sm"
            style={{ width: 250 }}
        >
            <option value="us" data-country="United States">United States</option>
            <option value="fr" data-country="Canada-FR">Canada-FR</option>
            <option value="en" data-country="Canada-EN">Canada-EN</option>
        </Form.Select>
    );
};

export default LocaleChange;
