import { create } from 'zustand';

const useIndexStore = create((set) => ({
    currentIndex: 0,
    setCurrentIndex: (index) => set(() => ({ currentIndex: index })),
    handleNextClick: () => set((state) => ({ currentIndex: state.currentIndex + 1 })),
    handleBackClick: () => set((state) => ({ currentIndex: state.currentIndex - 1 })),
}));

export default useIndexStore;
