import React, { Suspense, useEffect, useState, useCallback } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BrowserRouter, Routes, Route, Navigate, useNavigate, useParams, useLocation } from "react-router-dom";
import './App.css';
import i18n from './i18n';
import Layout from './layouts/layout';
import CreateCatalog from "./components/createCatalog";
import ShowCatalog from "./components/showCatalog";
import ShowCatalogFr from "./components/showCatalogFr";
import ShowCatalogEn from "./components/showCatalogEn";
import SelectCatalog from "./components/selectCatalog";
import NotFoundPage from './NotFoundPage';
import Loading from './assets/Loading';
import LocaleContext from './context/LocaleContext';
import useLocaleStore from './store/useLocaleStore';


function CreateCatalogPage() {
  const { lang } = useParams()
  const setLocale = useLocaleStore((state) => state.setLocale);

  useEffect(() => {
    if (["us", "fr", "en"].includes(lang)) {
      setLocale(lang); // Update the locale in the store
    }
  }, [lang, setLocale]);

  if (!["us", "fr", "en"].includes(lang)) {
    return <Navigate to="/404" />;
  }
  return (
    <CreateCatalog />
  )
}

function CatalogRoute() {
  const { catalogListID, lang } = useParams();
  const navigate = useNavigate();


  useEffect(() => {
    // Check if catalogListID is valid (alphanumeric and 24 characters)
    const isValidCatalogID = /^[a-zA-Z0-9]{24}$/.test(catalogListID);

    if (!isValidCatalogID) {
      // Redirect to the 404 page
      navigate('/404');
    }
  }, [catalogListID, navigate]);

  if (!lang) {
    return <ShowCatalog />;
  }
  // Render the component content
  return (
    <>
      <ShowCatalog />
    </>
  );
}

function CatalogRouteFR() {
  const { catalogListID } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if catalogListID is valid (alphanumeric and 24 characters)
    const isValidCatalogID = /^[a-zA-Z0-9]{24}$/.test(catalogListID);

    if (!isValidCatalogID) {
      // Redirect to the 404 page
      navigate('/404');
    }
  }, [catalogListID, navigate]);


  // Render the component content
  return (
    <>
      <ShowCatalogFr />
    </>
  );
}
function CatalogRouteEN() {
  const { catalogListID } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if catalogListID is valid (alphanumeric and 24 characters)
    const isValidCatalogID = /^[a-zA-Z0-9]{24}$/.test(catalogListID);

    if (!isValidCatalogID) {
      // Redirect to the 404 page
      navigate('/404');
    }
  }, [catalogListID, navigate]);


  // Render the component content
  return (
    <>
      <ShowCatalogEn />
    </>
  );
}


function DefaultRoute() {
  // Handle default behavior for the first load
  const navigate = useNavigate();
  const setLocale = useLocaleStore((state) => state.setLocale);

  useEffect(() => {
    setLocale('us'); // Set the default locale to 'us'
    navigate('/us');
  }, [navigate, setLocale]);

  return null; // Return null or a loading component as needed
}

function App() {
  const locale = useLocaleStore((state) => state.locale);


  useEffect(() => {
    i18n.changeLanguage(locale); // Update i18n with the current locale from the store
  }, [locale]);
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading.PageSpinner />}>
        <Layout>
          <Routes>
            <Route path="/" element={<DefaultRoute />} />
            <Route path="/:lang" element={<CreateCatalogPage />} />
            <Route path="/Catalog/:catalogListID" element={<CatalogRoute />} />
            <Route path="/Catalog/fr/:catalogListID" element={<CatalogRouteFR />} />
            <Route path="/Catalog/en/:catalogListID" element={<CatalogRouteEN />} />

            <Route exact path="/CustomPageSelector/:catalogName" element={<SelectCatalog />} />
            <Route path="/404" element={<NotFoundPage />} />
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </Layout>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
