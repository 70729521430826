import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import ShowSelectedImage from "../ShowSelectedImage";
import ApiLinks from "../../api/apis";
import { useTranslation } from "react-i18next";
import useCoverLogoStore from "../../store/useCoverLogoStore";
const hostUrl = ApiLinks.ActiveLink();
const CoverLogoUpload = () => {
    const { t } = useTranslation()
    const [coverFiles, setCoverFiles] = useState([]);
    const [coverImage, setCoverImage] = useState("");
    const [coverImageFileActive, setCoverImageFileActive] = useState("");
    const [coverModalVisible, setCoverModalVisible] = useState(false);
    const [coverSpinner, setCoverSpinner] = useState(false);
    const { setLogo } = useCoverLogoStore()

    const onDropFunction = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const selectedFile = acceptedFiles[0];
            if (
                selectedFile.type === "image/png" ||
                selectedFile.type === "image/svg+xml"
            ) {
                setCoverFiles(acceptedFiles);
                setCoverModalVisible(true);
                setCoverSpinner(true);

                var reader = new FileReader();
                reader.readAsDataURL(selectedFile);

                reader.onloadend = function () {
                    let base64data = reader.result;
                    setCoverImage(base64data);
                    setCoverSpinner(true);

                    // Process the cover logo (if needed)
                    fetch(`${hostUrl}images/process`, {
                        method: "POST",
                        headers: {
                            'Content-Type': "text/json"
                        },
                        body: JSON.stringify({
                            fileupload: base64data,
                        }),
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            setCoverImageFileActive(data.hasWhite ? data.upload : base64data);
                            setLogo(data.hasWhite ? data.upload : base64data)
                        })
                        .catch((err) => {
                            console.log("unable to process image " + err);
                            setCoverSpinner(false);
                            setCoverImage("");
                            alert("Error, please try a new logo.");
                        })
                        .finally(() => setCoverSpinner(false));
                };
            } else {
                alert("Invalid file type. Please upload a PNG or SVG image.");
            }
        }
    });

    const { getRootProps, getInputProps, open } = useDropzone({
        accept: {
            'image/png': ['.svg', '.png']
        }, maxFiles: 1, onDrop: onDropFunction, noClick: true
    });

    return (
        <div>
            <div
                {...getRootProps({})}
                className="product-logo-box d-flex flex-column justify-content-center align-items-center p-2 border rounded"
                onClick={open}
            >
                <input {...getInputProps()} name="coverlogo" className="inputt" />
                <img
                    src="/images/image-plus.svg"
                    alt="uploaded-img"
                    className={`mt-auto add-image-icon ${coverFiles.length === 1 ? "hide" : ""}`}
                />
                <div className="d-flex justify-content-center selected-image flex-column justify-content-center align-items-center">
                    <div
                        className={`${coverFiles.length === 0 ? "hide" : "d-flex"
                            } align-items-center justify-content-between gap-5`}
                    >
                        <div className="w-100">
                            <span className="fw-bold">Original</span>
                            <div className="margin-auto">
                                <ShowSelectedImage
                                    newimageone={coverImageFileActive}
                                    color={"colored"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`mt-auto text-center text-uppercase ${coverFiles.length === 1 ? "hide" : ""
                        }`}
                >
                    <div className="btn bg-yellow">
                        {t("Home.coverLogo")}
                    </div>
                </div>
            </div>

            {/* Modal for Cover Logo */}
            {coverModalVisible && (
                <div id="coverLogoModal" className="modal open">
                    <div className="modal-content preview">
                        <span className="close" onClick={() => setCoverModalVisible(false)}>
                            &times;
                        </span>
                        <div className="scrollable d-flex align-items-start upload-modal-sm">
                            <div className="w-100">
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                    <h2 className="text-center mb-2">{t("Home.coverLogoTitle")}</h2>
                                    <div className="d-flex align-items-center justify-content-center gap-5 flex-column flex-xl-row">
                                        <div>
                                            <span className=" fw-bold">{t("Home.original")}</span>
                                            <ShowSelectedImage
                                                newimageone={coverImage}
                                                color={"colored"}
                                            />
                                        </div>
                                    </div>

                                    <div className="w-full d-flex justify-content-around align-items-center gap-5 mt-4 flex-column flex-xl-row">
                                        <div className="upload-btn bg-gray" onClick={open}>
                                            {t("Home.reupload")}
                                        </div>
                                        {/* <div
                                            className="upload-btn bg-yellow"
                                            onClick={() => alert("Advance Image Processing")}
                                        >
                                            {t("Home.advanceImage")}
                                        </div> */}
                                        <div
                                            className="upload-btn bg-yellow"
                                            onClick={() => setCoverModalVisible((prev) => !prev)}
                                        >
                                            {t("Home.approve")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="line-container">
                                <div className="vl"></div>
                            </div> */}
                            {/* <div className="w-30 d-flex justify-content-center align-items-center">
                                <div className="info-desc mt-2">
                                    <h3 className="gray">
                                        <strong>For Best Results</strong>
                                    </h3>
                                    <p className="mt-3 mb-3">
                                        <strong className="gray semi-title">Background</strong> Keep
                                        your logo background transparent
                                    </p>
                                    <p className="mt-3 mb-3">
                                        <strong className="gray semi-title">Clarity</strong> Opt for
                                        a clear logo with high resolution
                                    </p>
                                    <p className="mt-3 mb-3">
                                        <strong className="gray semi-title">Simplicity</strong>{" "}
                                        Avoid complex logos with too many details
                                    </p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CoverLogoUpload;
