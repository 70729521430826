import ReactSlider from "react-slider";
import "./slider.css";
import { useTranslation } from "react-i18next";
import useIndexStore from "../../store/useIndexSliderStore";
import useLocaleStore from "../../store/useLocaleStore";
const Slider = ({ onChange }) => {
  const { t } = useTranslation()
  const locale = useLocaleStore((state) => state.locale);
  const { currentIndex, setCurrentIndex } = useIndexStore();

  const handleClick = (newIndex) => {
    setCurrentIndex(newIndex);

  };
  return (
    <>
      <ReactSlider
        className="horizontal-slider d-flex justify-content-evenly align-items-center"
        marks
        markClassName={`example-mark`}
        min={0}
        max={2}
        onChange={onChange}
        thumbClassName="example-thumb"
        trackClassName="example-track"
        renderMark={(props) => {
          const markIndex = props.key;
          if (markIndex < currentIndex) {
            props.className = `example-mark font-montserratbold example-mark-completed span-${markIndex + 1
              }`;
          } else if (markIndex === currentIndex) {
            props.className = `example-mark after:font-montserratbold after-text example-mark-active span-${markIndex + 1
              }`;
          } else {
            props.className = `example-mark after:font-montserratbold after-text span-${markIndex + 1
              }`;
          }

          let markText = "";

          switch (markIndex) {
            case 0:
              markText = t("Home.selectLookbook");
              break;
            case 1:
              markText = t("Home.uploadImageProg");
              break;
            case 2:
              markText = t("Home.enterEmail");
              break;

            default:
              markText = "Undefined Mark";
          }

          return (
            <span {...props} key={props.key}>
              {/* {isActionable ? (
                <div
                  className="flex items-center uppercase cursor-pointer"
                  onClick={() => handleClick(markIndex)}
                >
                  <p className="text-[5rem] xl:text-[8rem] font-montserratbold">
                    {markIndex + 1}
                  </p>
                  <div className="w-2 text-[5rem] xl:text-2xl font-montserratlight">
                    <p>{markText}</p>
                  </div>
                </div>
              ) : (
                <div className="flex items-center uppercase">
                  <p className="text-[5rem] xl:text-[8rem] font-montserratbold">
                    {markIndex + 1}
                  </p>
                  <div
                    className={` text-[1rem] ${locale === "fr" && markIndex === 2
                      ? "xl:text-2xl w-[10.5rem]"
                      : "xl:text-2xl w-2"
                      }  font-montserratlight`}
                  >
                    <p>{markText}</p>
                  </div>
                </div>
              )} */}
              <div className="flex items-center uppercase slider-wp">
                <p className="number-label font-montserratbold">
                  {markIndex + 1}
                </p>
                <div
                  className={`text-[1rem] text-label ${locale === "fr" && markIndex === 2
                    ? "xl:text-2xl french-width"
                    : "xl:text-2xl w-2"
                    }  font-montserratlight`}
                >
                  <p>{markText}</p>
                </div>
              </div>
            </span>
          );
        }}

      />

    </>
  );
};

export default Slider;