import { useState, useCallback, useEffect } from "react";
import ApiLinks from "../api/apis";
import { useTranslation } from "react-i18next";
import useLocaleStore from "../store/useLocaleStore";
import Modals from "../assets/Modals";
const hostUrl = ApiLinks.ActiveLink();
function GetCatalogs({ selectedCatalogs, setSelectedCatalogs, setCatalogFeatures, setModalSelected }) {
    const { t } = useTranslation()
    const [previewCatalog, setPreviewCatalog] = useState(false)
    const [selectedUrl, setSelectedUrl] = useState(null)
    let statwa = [];
    if (sessionStorage.getItem("catalogs")) {
        statwa = JSON.parse(sessionStorage.getItem("catalogs"));
    }
    const [catalogs, setCatalogs] = useState(statwa);
    const { locale } = useLocaleStore();

    const handleCatalogSelection = useCallback((catalogName, feature) => {
        setCatalogFeatures(feature);
        if (sessionStorage.getItem("logoType")) {
            console.log('clear');
            sessionStorage.removeItem("logoType");
        }

        setSelectedCatalogs((prevSelectedCatalogs) => {
            // Check if the catalog is already selected
            if (prevSelectedCatalogs.includes(catalogName)) {
                // Remove the catalog if it's already selected
                return prevSelectedCatalogs.filter((name) => name !== catalogName);
            } else {
                if (prevSelectedCatalogs.length < 3) {
                    // Only allow selection if there are fewer than 3 selected catalogs
                    return [...prevSelectedCatalogs, catalogName];
                } else {
                    // If 3 catalogs are already selected, trigger modal
                    setModalSelected(true);
                    return prevSelectedCatalogs;
                }
            }
        });
    }, [setCatalogFeatures, setSelectedCatalogs, setModalSelected]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let regionCode;
                switch (locale) {
                    case "fr":
                        regionCode = "ca/fr";
                        break;
                    case "us":
                        regionCode = "us/en";
                        break;
                    case "en":
                        regionCode = "ca/en";
                        break;
                    default:
                        regionCode = "us/en"; // Default URL
                }
                const url = hostUrl + `lookBook/r-l/${regionCode}`;
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                sessionStorage.setItem("catalogs", JSON.stringify(data));
                setCatalogs(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [locale]);

    const handlePreview = (catalogUrl) => {
        setSelectedUrl(catalogUrl)
        setPreviewCatalog(() => !previewCatalog)
    }
    return (
        <>
            <div className="d-flex flex-wrap flex-row gap-5 lookbook-list">
                {
                    catalogs?.map((catalog) => (
                        <div key={catalog.name} className={`card-custom ${selectedCatalogs.includes(catalog.name) ? "selected" : ""}  `}
                            style={{ cursor: 'pointer' }}
                        >
                            <div
                                className={`position-relative`}
                                onClick={() => handleCatalogSelection(catalog.name, catalog.feature)} // Add event handler here
                            >
                                <img className="card-img-top max-height" src={"/images/lookbooks/" + catalog.name + ".jpg"} alt={catalog.name} />
                                <div className={`check-mark ${selectedCatalogs.includes(catalog.name) ? "selected" : ""}`}>
                                    <span className="check">&#10003;</span>
                                </div>
                            </div>
                            <div
                            >
                                <div className="d-flex align-items-center justify-content-center position-relative mt-1">
                                    <h5 className="card-title text-center">{catalog.display}</h5>
                                    {(catalog.new === 1) ? (<p className="new">{t("Home.new")}</p>) : null}
                                </div>
                                <div className="preview-catalog mt-2" onClick={() => handlePreview(catalog.name)}>{t("Home.previewCatalog")}</div>
                            </div>
                        </div>
                    ))
                }

            </div>
            <Modals
                size="lg"
                show={previewCatalog}
                onHide={() => setPreviewCatalog(false)}
                className="modal-iframe"
            >
                {selectedUrl === "Jan25FR" && (
                    <iframe className="preview-iframe" src="https://gifting-fr-2025.vercel.app/" title={selectedUrl}></iframe>
                )}
                {selectedUrl === "Jan25" && (
                    <iframe className="preview-iframe" src="https://gifting-2025.vercel.app/" title={selectedUrl}></iframe>
                )}
                {selectedUrl === "Gifting24US" && (
                    <iframe className="preview-iframe" src="https://gifting-2024.vercel.app/" title={selectedUrl}></iframe>
                )}
                {selectedUrl === "Gifting24FR" && (
                    <iframe className="preview-iframe" src="https://giftingfr-2024.vercel.app/" title={selectedUrl}></iframe>
                )}
                {selectedUrl === "Gifting24CA" && (
                    <iframe className="preview-iframe" src="https://gifting-2024.vercel.app/" title={selectedUrl}></iframe>
                )}
                {selectedUrl === "Gifting23US" && (
                    <iframe className="preview-iframe" src="https://gift-usa.vercel.app/" title={selectedUrl}></iframe>
                )}
                {selectedUrl === "Gifting23CA" && (
                    <iframe className="preview-iframe" src="https://gift-canada.vercel.app/" title={selectedUrl}></iframe>
                )}
                {selectedUrl === "Ora23" && (
                    <iframe className="preview-iframe" src="https://gifting-ora.vercel.app/" title={selectedUrl}></iframe>
                )}
                {selectedUrl === "Ora23FR" && (
                    <iframe className="preview-iframe" src="https://gifting-ora-french.vercel.app/" title={selectedUrl}></iframe>
                )}
                {selectedUrl === "Gifting23FR" && (
                    <iframe className="preview-iframe" src="https://gifting-french.vercel.app/" title={selectedUrl}></iframe>
                )}
                {selectedUrl === "Jan24" && (
                    <iframe className="preview-iframe" src="https://jan-2024-us.vercel.app/" title={selectedUrl}></iframe>
                )}
                {selectedUrl === "Jan24FR" && (
                    <iframe className="preview-iframe" src="https://jan-2024-fr.vercel.app/" title={selectedUrl}></iframe>
                )}
                {selectedUrl === "TrendReportSetup24" && (
                    <iframe className="preview-iframe" src="https://trendreport-2024-us.vercel.app/" title={selectedUrl}></iframe>
                )}
                {selectedUrl === "TrendReportSetup24FR" && (
                    <iframe className="preview-iframe" src="https://trendreport-2024-fr.vercel.app/" title={selectedUrl}></iframe>
                )}
            </Modals>
        </>
    );
}

export default GetCatalogs